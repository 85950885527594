<template>
  <div>
    <vx-card>
      <div class="flex flex-wrap justify-between items-center mb-1">
        <div class="mb-4 md:mb-0 mr-4">
          <div class="flex"></div>
        </div>

        <div class="flex flex-wrap items-center sm:justify-between w-full sm:w-auto">
          <!--limit chooser-->
          <PagingLimit :currentPage="table.page" :limit="table.limit" :total="table.totalItems" @changeLimit="onPageLimitChange"/>

          <div class="w-full sm:w-auto flex">
            <!--search-->
            <vs-input icon-pack="feather" icon="icon-search" class="mb-4 md:mb-0 mr-4 w-full" placeholder="Cari" v-model="table.search" @keyup="onSearch"/>

            <!--actions-->
            <vs-dropdown vs-trigger-click class="dd-actions cursor-pointer">
              <vs-button class="mb-4 md:mb-0 px-3 py-3 flex items-center justify-center" type="filled" icon-pack="feather" icon="icon-more-vertical"/>
              <vs-dropdown-menu>
                <vs-dropdown-item>
                  <ExportCsv v-if="$refs.mainTable" :data="$refs.mainTable._props.data" />
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>
        </div>
      </div>

      <!--loading-->
      <vs-progress indeterminate color="primary" :height="1" :class="{ 'invisible': !table.loading }"/>

      <!--table-->
      <vs-table
        ref="mainTable"
        :class="{ 'animate-pulse': table.loading }"
        :sst="true"
        :data="table.rows"
        @sort="onSort"
        stripe>
        <template slot="thead">
          <vs-th class="whitespace-no-wrap" v-if="selectable"></vs-th>
          <vs-th class="whitespace-no-wrap text-center">Action</vs-th>
          <vs-th class="whitespace-no-wrap text-center">Status</vs-th>
          <vs-th class="whitespace-no-wrap text-center">Status Approval</vs-th>
          <vs-th class="whitespace-no-wrap text-center">ID</vs-th>
          <vs-th class="whitespace-no-wrap text-center">No. Komisi</vs-th>
          <vs-th class="whitespace-no-wrap text-center">Staf Penerima</vs-th>
          <vs-th class="whitespace-no-wrap text-center">Tgl. Pengajuan</vs-th>
          <vs-th class="whitespace-no-wrap text-center">Total Pengajuan</vs-th>
          <vs-th class="whitespace-no-wrap text-center">Total Komisi</vs-th>
          <vs-th class="whitespace-no-wrap text-center">Keterangan</vs-th>
          <vs-th class="whitespace-no-wrap text-center">Created By</vs-th>
          <vs-th class="whitespace-no-wrap text-center">Created At</vs-th>
        </template>
        <template slot-scope="{data}">
          <vs-tr v-for="item in data" :key="item.id" class="text-sm">
            <vs-td v-if="selectable">
              <vs-button class="py-2 px-3" type="border" @click="onSelected(item)">Pilih</vs-button>
            </vs-td>
            <vs-td v-if="!selectable">
              <div class="flex">
                <vs-button class="py-2 px-3" type="border" icon-pack="feather" icon="icon-eye" :to="{ name: 'approvals.komisiApproval.show', params: { idKomisiPayment: item.id } }"/>
              </div>
            </vs-td>
            <vs-td class="whitespace-no-wrap">
              <div class="flex items-center">
                <div class="text-white rounded-sm text-center items-center px-1 rounded" :class="{'bg-success': item.signed_by, 'bg-orange': !item.signed_by}">
                  <span class="text-xs">{{ item.signed_by !== null ? 'Sudah Ditanggapi' : 'Belum Ditanggapi' }}</span>
                </div>
              </div>
            </vs-td>
            <vs-td class="whitespace-no-wrap">
              <div class="flex items-center">
                <div class="text-white rounded-sm text-center items-center px-1 rounded" :class="'bg-' + item.status_approval_color">
                  <span class="text-xs">{{ item.status_approval }}</span>
                </div>
              </div>
            </vs-td>
            <vs-td class="whitespace-no-wrap">#ID{{ item.id }}</vs-td>
            <vs-td class="whitespace-no-wrap">{{ item.no_komisi }}</vs-td>
            <vs-td class="whitespace-no-wrap">{{ item.nama_staf }}</vs-td>
            <vs-td class="whitespace-no-wrap">{{ item.tgl_diajukan }}</vs-td>
            <vs-td class="whitespace-no-wrap text-right">{{ item.jml_komisi | idr }}</vs-td>
            <vs-td class="whitespace-no-wrap text-right">{{ item.total_komisi | idr }}</vs-td>
            <vs-td class="whitespace-no-wrap">{{ item.keterangan || '-' }}</vs-td>
            <vs-td class="whitespace-no-wrap">{{ item.username }}</vs-td>
            <vs-td class="whitespace-no-wrap">{{ item.created_at }}</vs-td>
          </vs-tr>
        </template>
      </vs-table>

      <!--paging-->
      <div>
        <vs-pagination class="text-sm mt-6 overflow-x-scroll hidden sm:block" v-model="table.page" @change="onPageChange" :total="totalPages"/>
        <div class="flex w-full justify-end mt-6 justify-around sm:hidden">
          <vs-button radius color="primary" type="border" icon-pack="feather" icon="icon-chevron-left" @click="table.page > 1 && table.page--; onPageChange"/>
          <vs-button radius color="primary" type="border" icon-pack="feather" icon="icon-chevron-right" @click="table.page < totalPages && table.page++; onPageChange"/>
        </div>
      </div>

    </vx-card>
  </div>
</template>

<script>
import KomisiApprovalRepository from '@/repositories/approvals/komisi-approval-repository'
import PagingLimit from '@/views/components/paging-limit/PagingLimit'
import _ from 'lodash'

export default {
  name: 'KomisiApproval',
  components: {
    ExportCsv: () => import('@/views/components/export-csv/ExportCsv'),
    PagingLimit
  },
  props: {
    selectable: { default: false, type: Boolean },
    externalFilter: { default: null, type: Object }
  },
  watch: {
    externalFilter (newVal, oldVal) {
      if (newVal && !_.isEmpty(newVal) && !_.isEqual(newVal, oldVal)) {
        this.getData()
      }
    }
  },
  data () {
    return {
      table: {
        loading: false,
        page: 1,
        limit: 10,
        totalItems: 0,
        sort: '',
        search: '',
        rows: []
      }
    }
  },
  computed: {
    totalPages () {
      return Math.ceil(this.table.totalItems / this.table.limit)
    }
  },
  methods: {
    getData () {
      this.table.loading = true
      const params = (({ page, limit, search, sort }) => ({ page, limit, search, sort }))(this.table)

      if (this.externalFilter) {
        params.externalFilter = JSON.stringify(this.externalFilter)
      }

      KomisiApprovalRepository.get(params)
        .then(response => {
          this.table.rows = response.data.data.data
          this.table.totalItems = response.data.data.total
          if (this.table.page > response.data.data.last_page) {
            this.table.page = response.data.data.last_page
          }
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.table.loading = false
        })
    },

    onPageChange () {
      this.getData()
    },

    onPageLimitChange (limit) {
      this.table.limit = limit
      if (this.table.page > this.totalPages) {
        this.table.page = this.totalPages
      }
      this.getData()
    },

    onSelected (item) {
      const itemCopy = Object.assign({}, item)
      this.$emit('selected', itemCopy)
    },

    onSort (key, order) {
      this.table.sort = (key === null || order === null) ? '' : `${key} ${order}`
      this.getData()
    },

    onSearch: _.debounce(function (e) {
      this.getData()
    }, 500)
  }
}
</script>
